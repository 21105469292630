import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Script {
    name: string;
    src: string;
    version?: string;
    attributes?: {
        referrerpolicy?: string;
        async?: boolean | string;
        'data-site-id'?: string;
        'data-mode'?: string;
    };
}

export enum Scripts {
    STRIPE = 'stripe',
    PAYPAL = 'paypal',
    BRAINTREE = 'braintree',
    BRAINTREE_CHECKOUT = 'braintree_checkout',
    BRAINTREE_DATA_COLLECTOR = 'braintree_data_collector',
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
    HLS = 'hls',
    DASH = 'dash',
    PARTICLES = 'particles',
    RANGETOUCH = 'rangetouch',
    TYPEWRITER = 'typewriter',
    PLAYER = 'player',
    PLAYER_MUX = 'player-mux',
    TINYMCE = 'tinymce',
    TINYMCE_PLUGINS = 'tinymce_plugins',
    PUBLIFT = 'publift',
    PUBLIFT_GOOGLETAG = 'publift_googletag',
    VENATUS = 'venatus',
    WOWHEAD = 'wowhead'
}

export const ScriptStore: Script[] = [
    { name: 'stripe', src: 'https://js.stripe.com/v3/' },
    { name: 'paypal', src: 'https://www.paypalobjects.com/api/checkout.js', version: '4' },
    { name: 'braintree', src: 'assets/lib/braintree-client-3.72.0.min.js' },
    {
        name: 'braintree_checkout',
        src: 'assets/lib/braintree-paypal-checkout-3.72.0.min.js'
    },
    {
        name: 'braintree_data_collector',
        src: 'assets/lib/braintree-data-collector-3.72.0.min.js'
    },
    { name: 'facebook', src: 'https://connect.facebook.net/en_US/sdk.js' },
    { name: 'google', src: 'https://apis.google.com/js/platform.js' },
    { name: 'hls', src: 'assets/lib/hls.js-0.11.0.min.js' },
    { name: 'dash', src: 'assets/lib/dash.all.min-2.9.1.js' },
    { name: 'particles', src: 'assets/lib/particles.min.js' },
    { name: 'rangetouch', src: 'assets/lib/plyr/rangetouch.js' },
    { name: 'typewriter', src: 'assets/lib/typewriter-effect-core.js' },
    { name: 'player', src: '/assets/lib/shaka/4.3.6/dist/shaka-player.compiled.js' },
    { name: 'player-mux', src: '/assets/lib/shaka/mux-5.7.0.min.js' },
    { name: 'tinymce', src: '/assets/lib/tinymce/tinymce.min.js' },
    {
        name: 'tinymce_plugins',
        src:
            'https://cdn.tiny.cloud/1/xhc9436xcbdglm2x3tj6v40p145sosnbby9ipu1bmv98d6vm/tinymce/6/plugins.min.js',
        attributes: {
            referrerpolicy: 'origin'
        }
    },
    { name: 'publift', src: 'https://cdn.fuseplatform.net/publift/tags/2/3368/fuse.js' },
    { name: 'publift_googletag', src: '/assets/lib/fuse/googletag.js' },
    { name: 'venatus', src: '/assets/lib/fuse/googletag.js' },
    {
        name: 'venatus',
        src: 'https://hb.vntsm.com/v3/live/ad-manager.min.js',
        attributes: {
            'data-site-id': '65311c7dce2d802e0a0232ea',
            'data-mode': 'scan',
            async: true
        }
    },
    {
        name: 'wowhead',
        src: 'https://wow.zamimg.com/js/tooltips.js',
        attributes: {
            async: true
        }
    }
];

export interface ScriptStatus {
    name: string;
    loaded: boolean;
    status: string;
}

@Injectable()
export class ScriptService {
    private scripts: any = {};
    private loadStatuses: ScriptStatus[] = [];
    private scriptsStatusesSubject: BehaviorSubject<ScriptStatus[]> = new BehaviorSubject<
        ScriptStatus[]
    >(this.loadStatuses);

    public scriptsStatuses$ = this.scriptsStatusesSubject.asObservable();

    constructor(@Inject(DOCUMENT) private document: Document) {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src,
                ...script
            };
        });
    }

    public load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach(script => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    public loadScript(name: string, placement?: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                let script: any = this.document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (this.scripts[name].version) {
                    script.setAttribute('data-version-' + this.scripts[name].version, '');
                }

                const attributes = this.scripts[name].attributes
                    ? Object.keys(this.scripts[name].attributes)
                    : [];

                attributes.forEach(attribute => {
                    script.setAttribute(attribute, this.scripts[name].attributes[attribute]);
                });

                if (script && script.readyState) {
                    // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            const status: ScriptStatus = {
                                name: name,
                                loaded: true,
                                status: 'Loaded'
                            };
                            this.loadStatuses.push(status);
                            this.scriptsStatusesSubject.next(this.loadStatuses);
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        const status: ScriptStatus = {
                            name: name,
                            loaded: true,
                            status: 'Loaded'
                        };
                        this.loadStatuses.push(status);
                        this.scriptsStatusesSubject.next(this.loadStatuses);
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => {
                    const status: ScriptStatus = {
                        name: name,
                        loaded: false,
                        status: 'Error'
                    };
                    this.loadStatuses.push(status);
                    this.scriptsStatusesSubject.next(this.loadStatuses);

                    resolve({ script: name, loaded: false, status: 'Error' });
                };
                this.document.getElementsByTagName(placement || 'body')[0].appendChild(script);
            }
        });
    }

    public loadStyle(path: string) {
        const head = document.getElementsByTagName('head')[0];

        let themeLink = document.getElementById('client-theme') as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = path;
        } else {
            const style = document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.href = `${path}`;

            head.appendChild(style);
        }
    }
}

